



.break {
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
  height: 0;
}
.row {
  display: block;
  margin: 0 auto;
  max-width: 1138px;
  overflow: hidden;
  zoom: 1;
}
.row::before,
.row::after {
  content: "";
  display: table;
  line-height: 0;
}
.row::after {
  clear: both;
}
.row .row {
  max-width: 100%;
  min-width: 100%;
}
.row .column,
.row .columns {
  float: left;
  padding: 10px;
  width: 100%;
}
.row .column:first-of-type,
.row .columns:first-of-type {
  padding-left: 72px;
}
.row .column:last-of-type,
.row .columns:last-of-type {
  padding-right: 72px;
}
.wide {
  max-width: 1180px;
}
.wider {
  max-width: 1366px;
}
.free-width {
  max-width: none;
}
.column .column:first-of-type,
.column .columns:first-of-type,
.columns .column:first-of-type,
.columns .columns:first-of-type {
  padding-left: 0;
}
.column .column:last-of-type,
.column .columns:last-of-type,
.columns .column:last-of-type,
.columns .columns:last-of-type {
  padding-right: 0;
}
.column .row:first-of-type > .column,
.column .row:first-of-type > .columns,
.columns .row:first-of-type > .column,
.columns .row:first-of-type > .columns {
  padding-top: 0;
}
.column .row:last-of-type > .column,
.column .row:last-of-type > .columns,
.columns .row:last-of-type > .column,
.columns .row:last-of-type > .columns {
  padding-bottom: 0;
}
.column.no-padding,
.columns.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.column.one,
.columns.one {
  width: 8.33333%;
}
.column.two,
.columns.two {
  width: 16.66667%;
}
.column.three,
.columns.three {
  width: 25%;
}
.column.four,
.columns.four {
  width: 33.33333%;
}
.column.five,
.columns.five {
  width: 41.66667%;
}
.column.six,
.columns.six {
  width: 50%;
}
.column.seven,
.columns.seven {
  width: 58.33333%;
}
.column.eight,
.columns.eight {
  width: 66.66667%;
}
.column.nine,
.columns.nine {
  width: 75%;
}
.column.ten,
.columns.ten {
  width: 83.33333%;
}
.column.eleven,
.columns.eleven {
  width: 91.66667%;
}
.column.twelve,
.columns.twelve {
  width: 100%;
}
.column.whole,
.column.w-1-1,
.columns.whole,
.columns.w-1-1 {
  width: 100%;
}
.column.half,
.column.w-1-2,
.columns.half,
.columns.w-1-2 {
  width: 50%;
}
.column.one-third,
.column.w-1-3,
.columns.one-third,
.columns.w-1-3 {
  width: 33.33333%;
}
.column.two-thirds,
.column.w-2-3,
.columns.two-thirds,
.columns.w-2-3 {
  width: 66.66667%;
}
.column.one-quarter,
.column.one-fourth,
.column.w-1-4,
.columns.one-quarter,
.columns.one-fourth,
.columns.w-1-4 {
  width: 25%;
}
.column.three-quarters,
.column.three-fourths,
.column.w-3-4,
.columns.three-quarters,
.columns.three-fourths,
.columns.w-3-4 {
  width: 75%;
}
.column.one-fifth,
.column.w-1-5,
.columns.one-fifth,
.columns.w-1-5 {
  width: 20%;
}
.column.two-fifths,
.column.w-2-5,
.columns.two-fifths,
.columns.w-2-5 {
  width: 40%;
}
.column.three-fifths,
.column.w-3-5,
.columns.three-fifths,
.columns.w-3-5 {
  width: 60%;
}
.column.four-fifths,
.column.w-4-5,
.columns.four-fifths,
.columns.w-4-5 {
  width: 80%;
}
.offset.by-one {
  margin-left: 8.33333%;
}
.offset.by-two {
  margin-left: 16.66667%;
}
.offset.by-three {
  margin-left: 25%;
}
.offset.by-four {
  margin-left: 33.33333%;
}
.offset.by-five {
  margin-left: 41.66667%;
}
.offset.by-six {
  margin-left: 50%;
}
.offset.by-seven {
  margin-left: 58.33333%;
}
.offset.by-eight {
  margin-left: 66.66667%;
}
.offset.by-nine {
  margin-left: 75%;
}
.offset.by-ten {
  margin-left: 83.33333%;
}
.offset.by-eleven {
  margin-left: 91.66667%;
}
.offset.by-half {
  margin-left: 50%;
}
.offset.by-one-third {
  margin-left: 33.33333%;
}
.offset.by-two-thirds {
  margin-left: 66.66667%;
}
.offset.by-one-quarter,
.offset.by-one-fourth {
  margin-left: 25%;
}
.offset.by-three-quarters,
.offset.by-three-fourths {
  margin-left: 75%;
}
.offset.by-one-fifth {
  margin-left: 20%;
}
.offset.by-two-fifths {
  margin-left: 40%;
}
.offset.by-three-fifths {
  margin-left: 60%;
}
.offset.by-four-fifths {
  margin-left: 80%;
}
.only-on-mobiles {
  display: none !important;
}
.hide {
  display: none !important;
}
.dynamic-height {
  height: 100vh;
  min-height: 41rem;
}
.dynamic-height-secondary {
  height: 70vh;
}
.dynamic-height-secondary {
  height: 70vh;
}
.flex-grow {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
@media screen and (max-width: 780px) {
  
  .dynamic-height {
    height: auto !important;
    min-height: 41rem;
    padding: 2rem 0;
  }

  .row:not(.no-stacking-on-mobiles) > .column,
  .row:not(.no-stacking-on-mobiles) > .columns {
    padding-left: 20px;
    padding-right: 20px;
    width: 100% !important;
  }
  .column .row .column,
  .column .row .columns,
  .columns .row .column,
  .columns .row .columns {
    padding-left: 0px;
    padding-right: 0px;
  }
  .center-on-mobiles {
    text-align: center !important;
  }
  .hide-on-mobiles {
    display: none !important;
  }
  .only-on-mobiles {
    display: block !important;
  }
  .offset {
    margin-left: 0 !important;
  }
  .column--no-padding {
    padding: 0 !important;
  }
}
@media screen and (min-width: 1024px) {
  .wide .row,
  .row .wide {
    margin: 0 auto;
    max-width: 1024px;
  }
}
@media screen and (min-width: 1440px) {
  .wider .row,
  .row .wider {
    margin: 0 auto;
    max-width: 1440px;
  }
}
.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}
@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}
@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}
.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}
@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}
@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  scroll-behavior: smooth;
}
body {
  position: relative;
  font-family: "Poppins", "Lato", Helvetica, sans-serif;
  color: #272727;
  background-color: white;
}

h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.575rem;
}
h4 {
  font-size: 1.125rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 0.875rem;
}
p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8;
}
ul {
  margin: 0;
}
a {
  text-decoration: none;
}
strong,
b {
  color: black;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
.no-scroll {
  overflow: hidden;
}
.u--font-light {
  font-weight: 300;
}
.u--font-regular {
  font-weight: 400;
}
.u--font-heavy {
  font-weight: 500;
}
.u--c--white {
  color: white !important;
}
.u--a--center {
  text-align: center;
}

.u--left {
  text-align: left;
}
.u--right {
  text-align: right;
}
.u--f-left {
  float: left;
}
.u--f-right {
  float: right;
}
.u--center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.u--thin {
  font-weight: 400 !important;
}
.u--justify {
  text-align: justify;
}
.u--no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.u--no-margin-t {
  maring-top: 0;
}
@media screen and (max-width: 780px) {
  .u--desktop-only {
    display: none !important;
  }

  .u-m--f-none {
    float: none !important;
  }
  .u-m--no-padding-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-m--no-padding-lr {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .u-m--no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media screen and (min-width: 780px) {
  .u--mobile-only {
    display: none !important;
  }

  .u-d--no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .u-d--no-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.header {
  padding-left: 20px;
  height: 72px;
  line-height: 72px;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 99;
  background: white;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.header.active-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 0px; 
  -webkit-transform: translateY(-73px); 
          transform: translateY(-73px); 
}
.header.active-scroll.active-scroll--notification-bar {
  top: 59px;
}
.header.scroll-up {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.header__img {
  height: 40px;
  display: inline-block;
  vertical-align: top;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  background: white;
  width: 53px;
}
.header__link {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  -webkit-order: 0;
      -ms-flex-order: 0;
          order: 0;
}
.header__nav {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 72px;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header__nav-item {
  position: relative;
  line-height: 72px;
  text-decoration: none;
  font-weight: 500;
  line-height: 72px;
  color: #000;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 0.5rem;
  font-size: 0.8rem;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 6rem;
  
  cursor: pointer;
}
.header__nav-item:hover:not(.multi-item-menu__item) .header__nav-item-span {
  border-bottom: 3px solid #51c2e7;
}
.header__nav-item.multi-item-menu__item:hover .header__nav-item-span {
  color: #51c2e7;
}
.header__nav-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.header__nav-wrap > .header__nav-item {
  max-width: none !important;
  padding: 0 16px;
}
.header__side-section {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start;
}
.header__nav-sm {
  display: inline-block;
  line-height: 72px;
  height: 72px;
}
.header__nav-sm--media {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 32px;
}
.header__nav-sm--media svg {
  color: #000;
  width: 29px;
}
.header__nav-sm--media:hover svg {
  color: #51c2e7;
}
.header__nav-sm-icon {
  font-size: 1.2rem;
  width: 56px;
  text-align: center;
}
.header__nav-item-span.active,
.header__nav-item.active .header__nav-item-span {
  border-bottom: 3px solid #51c2e7;
}
.multi-item-menu__item-label {
  height: 100%;
}

.hamburger {
  padding-right: 24px;
  padding-left: 20px;
  display: none;
  justify-items: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;

  
  -webkit-order: 1;

  
      -ms-flex-order: 1;

  
          order: 1;
}

.header__nav-item--container {
  position: relative;
  padding: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  padding: 0 16px;
  cursor: pointer;
  white-space: nowrap;
}
.header__nav-item--container:hover > .multi-item-menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.multi-item-menu {
  position: absolute;
  top: 73px;
  border: 2px solid #51c2e7;
  background-color: #fff;
  z-index: 99;
  width: 17rem;
  left: 0;
  display: none;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.multi-item-menu__item {
  width: 100%;
  max-width: 100%;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  line-height: 52px;
}
.multi-item-menu__item > .header__nav-item-span {
  padding: 0 20px;
  text-align: left;
}
.multi-item-menu--dynamic {
  top: 0;
  left: 17rem;
}

@media screen and (min-width: 780px) {
  .header__nav {
    height: 72px !important;
  }
  .header__nav-item-dynamic-container:hover > .multi-item-menu {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .header__nav-item--container:hover > .multi-item-menu .header__nav-item {
    min-height: 56px;
  }
  .header__nav-item.active,
  .header__nav-item-span.active,
  .header__nav-item.active .header__nav-item-span {
    height: 72px;
  }
}
@media screen and (max-width: 740px) {
  .header__nav-wrap > .header__nav-item {
    padding: 0 10px;
  }
}

@media screen and (max-width: 780px) {
  .header__nav-sm--media {
    padding: 0 22px;
  }
  .hamburger {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .multi-item-menu__item > .header__nav-item-span {
    padding: 0;
  }
  .header__nav-lvl-2 {
    padding-left: 3rem !important;
  }
  .header__nav-lvl-3 {
    padding-left: 4rem !important;
  }
  .header__nav {
    overflow: hidden;
    display: none;
    background: rgba(39,39,39, 0.9);
    padding-bottom: 50px;
  }
  
  .header__nav-item.active .header__nav-item-span {
    border-bottom: 3px solid #51c2e7;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .header__social-media {
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .is-active .header__nav {
    
    height: 25rem;
    position: absolute;
    width: 100%;
    top: 72px;
    left: 0;
    display: block;
    overflow-y: scroll;
    background: #fff;
    z-index: 99;
    padding-bottom: 120px;
  }
  .is-active .header__nav-wrap {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .is-active .header__nav-item:not(.standalone) {
    width: 100%;
    max-width: initial;
    font-size: 1rem;
    line-height: inherit;
    border-bottom: 2px solid #ebebeb;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    height: 4rem;
    padding: 0 2rem;
  }
  .is-active
    .header__nav-item:hover:not(.multi-item-menu__item)
    .header__nav-item-span {
    border: none;
    color: #51c2e7;
  }
  .header__nav-sm {
    line-height: 3.6rem;
  }
  .header__nav-sm-icon {
    font-size: 1.5rem;
  }
  .multi-item-menu {
    position: static;
    min-width: auto;
    display: block;
    width: 100%;
    border: none;
  }
  .header__nav-item-dynamic-container:hover {
    height: auto;
  }
  .multi-item-menu--dynamic {
    display: none;
  }
  .header__nav-item--container {
    padding: 0;
    width: 100%;
    max-width: 100%;
    display: block;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
  .active-menu > .multi-item-menu--dynamic {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .header__nav-item.active .header__nav-item-span {
    height: 100%;
  }

  .multi-item-menu__item > .header__nav-item-span {
    
    max-width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
footer {
  padding: 50px 0 50px 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.9) 44.39%,
    #000000 100%
  );
  color: white;
}
.footer__img {
  margin-left: 3px;
  margin-bottom: 15px;
  margin-top: 30px;
  width: 76px;
  display: block;
  padding-left: 20px;
  box-sizing: content-box;
}
.footer__text {
  padding-bottom: 25px;
  display: block;
}
.footer__text,
.footer__text strong {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.footer-links-column a {
  font-size: 14px;
  color: #fff;
  margin: 0 0 10px 0;
  display: block;
  cursor: pointer;
}
.footer-links-column a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 780px) {
  .middle-column {
    display: none;
  }
}

.hero-animation {
  z-index: -2;
  position: fixed;
  top: min(23vh, 340px);
  right: 0;
  width: 47%;
  height: 35vh;
  min-height: 500px;
  opacity: 0;
}

.svg-logotype {
  position: absolute;
  top: 0;
  left: 0;
}
.svg-banner {
  position: absolute;
  top: 0;
  left: 0;
}
.svg-logo-wall-left {
  position: absolute;
  top: 0;
  left: 0;
}
.svg-logo-wall-right {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 780px) {
  .svg-bg-grey-left {
    display: none;
  }
  .svg-bg-blue {
    x: 0;
    width: 50%;
  }
  .svg-logo-type {
    x: -20rem;
  }
}

.animate-text {
  -webkit-animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
.animate-banner {
  -webkit-animation: fade-in-left 1.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 1.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.secondary-svg-banner {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  overflow: hidden;
}
.secondary-svg-banner__wrap {
  height: 100%;
  display: block;
  -webkit-transform: translateX(29%);
          transform: translateX(29%);
}
@media screen and (max-width: 780px) {
  .secondary-svg-banner__wrap {
    height: 100%;
    display: block;
    -webkit-transform: none;
            transform: none;
  }
  .svg-mid-circle {
    left: 1.3rem;
    top: 125rem;
    display: none;
  }
}
.btn {
  font-family: Oswald, sans-serif;
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  outline: 0;
  border: 0;
  display: inline-block;
  zoom: 1;
  line-height: normal;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}
.btn-primary {
  background-color: #272727;
  color: white;
  padding: 0.6rem 1rem;
  min-width: 12rem;
}
.btn-primary--reverse {
  background-color: white;
  color: #272727;
}
.btn-primary:focus,
.btn-primary:hover {
  background-color: #51c2e7;
  color: white;
}
.btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.btn:focus,
.btn:hover {
  filter: alpha(opacity=90);
  background-image: linear-gradient(
    transparent,
    rgba(0, 0, 0, 0.05) 40%,
    rgba(0, 0, 0, 0.1)
  );
}
.btn:focus {
  outline: 0;
}
.btn[disabled] {
  border: none;
  background-image: none;
  filter: alpha(opacity=40);
  opacity: 0.4;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
}
.btn--section {
  margin-top: 2rem;
  display: block;
  text-transform: uppercase;
}
.btn--wide {
  width: 100%;
}
.btn-highlight {
  background-color: #ffc72d;
  color: white;
  padding: 0.6rem 1rem;
  min-width: 12rem;
}
.btn-highlight:focus,
.btn-highlight:hover {
  background-color: #196282;
  color: white;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: white;
  opacity: 1;
  font-weight: 400;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input:-moz-placeholder,
textarea:-moz-placeholder,
input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input::placeholder,
textarea::placeholder {
  color: white;
  opacity: 1;
  font-weight: 400;
}
.input-primary,
.textarea-primary {
  padding: 0.6rem 0.8rem;
  background-image: none;
  background-color: white;
  font-family: inherit;
  border: 2px solid #000000;
  display: block;
  color: #010101;
  border-radius: 0;
  width: 100%;
  font-size: 16px;
}
.input-primary:hover,
.textarea-primary:hover {
  border-color: #545454;
}
.input-primary:focus,
.textarea-primary:focus {
  border: 2px solid #272727;
  box-shadow: none;
  outline: none;
}
.input-contact:focus,
.textarea-contact:focus {
  box-shadow: none;
  outline: none;
}
.input-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  gap: 30px;
}
.input-row > input {
  width: 100%;
}
@media only screen and (max-width: 780px) {
  .input-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    gap: 32px;
  }
}
.contact-form-static .row {
  margin-bottom: 12px;
}
.contact-form-static .row:last-child {
  padding-top: 8px;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

.section-title,
.section-title--white {
  font-weight: 800;
  font-size: 40px;
  margin-top: 5px;
  line-height: 1;
}
.section-subtitle,
.section-subtitle--white {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
}
.section-title-secondary,
.section-title-secondary--white {
  line-height: 1.3;
  font-weight: 800;
  font-size: 24px;
  margin-top: 0;
}
.section-title--white,
.section-subtitle--white,
.section-title-secondary--white {
  color: #fff;
}
@media only screen and (max-width: 780px) {
  .section-title,
  .section-title--white {
    font-weight: 800;
    font-size: 32px;
    line-height: 130%;
  }

  .section-subtitle,
  .section-subtitle--white {
    line-height: 150%;
  }
}

.btn--large {
  padding: 14px;
  background: #ffc72d;
  border: 2px solid #000000;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  font-weight: bold;
  font-size: 16px;
  color: black;
  outline: none;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  cursor: pointer;
  display: inline-block;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
  line-height: 110%;
}
.btn--large:hover,
.btn--large:focus {
  background: #fab700;
  -webkit-transform: translateY(-4px) !important;
          transform: translateY(-4px) !important;
}
@media only screen and (max-width: 780px) {
  .btn--large {
    padding: 10px 14px;
    background: white;
  }
}
.btn--secondary {
  border: none;
  outline: none;
  background: #51c2e7;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: inherit;
  color: #010101;
  font-size: 12px;
  padding: 10px 20px;
  font-weight: 600;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
}
.btn--secondary:hover,
.btn--secondary:focus {
  background: #1da6d3;
  -webkit-transform: translateY(-4px) !important;
          transform: translateY(-4px) !important;
}
.btn-cta--white {
  border: none;
  outline: none;
  background: transparent;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: inherit;
  color: white;
  font-size: 12px;
  padding: 10px 20px;
  font-weight: 600;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;

  border: solid 2px #fff;
  font-size: 16px;
  font-weight: 700;
}
.btn-cta--white:hover,
.btn-cta--white:focus {
  background: white;
  color: #010101;
  -webkit-transform: translateY(-4px) !important;
          transform: translateY(-4px) !important;
}

.notification-bar {
  background-color: black;
  position: -webkit-sticky;
  position: sticky;
  top: -1px; 
  left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 100;
}
.notification-bar > div {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
}
.notification-bar span {
  color: #ffffff;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin: 0px 16px;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  font-size: 14px;
}
.notification-bar a {
  padding: 6px 12px;
  height: 30px;
  background: #ffc72d;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin: 0px 16px;
  font-weight: bold;
  font-size: 12px;
  color: black;
  outline: none;
  border: none;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  cursor: pointer;
}
.notification-bar a:hover {
  background: #fab700;
}
.carousel-container {
  padding-left: calc(((100% - 1138px) / 2) + 72px);
  padding-right: 20px;
}
.carousel-container::-webkit-scrollbar,
.carousel-container::-webkit-scrollbar-track {
  background: transparent;
}

@media only screen and (max-width: 1180px) {
  .carousel-container {
    padding-left: 72px;
  }
}
@media only screen and (max-width: 780px) {
  .carousel-container {
    padding-left: 20px;
  }
}

.bg-graphic__container {
  position: relative;
}
.bg-graphic__container--inner {
  position: absolute;
  z-index: -1;
  top: -100px;
  height: 100vh;
  width: 100%;
  left: 0;
  overflow: hidden;
}
.bg-graphic--footer {
  top: -30vh;
  right: -20px;
  height: 500px;
}
.bg-graphic--footer img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.btn--contact-form {
  width: 174px;
  display: block;
  font-family: inherit;
  margin-top: 15px;
}

.navigation-footer {
  margin-top: 100px;
}
.navigation-footer section {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.navigation-footer section a {
  font-weight: 800;
  font-size: 32px;
  line-height: 136%;
  color: #000000;
  text-transform: uppercase;
}
.navigation-footer section a.disabled {
  color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  cursor: default;
}
.navigation-footer section a:first-child:not(.disabled) > img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.navigation-footer section a:first-child:not(.disabled):hover > img {
  -webkit-transform: rotate(180deg) translateX(5px);
          transform: rotate(180deg) translateX(5px);
}
.navigation-footer section a:not(.disabled):hover > img {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}
.navigation-footer section img {
  width: 28px;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
}
@media only screen and (max-width: 780px) {
  .navigation-footer section a {
    font-weight: 800;
    font-size: 24px;
    line-height: 136%;
  }

  .navigation-footer section img {
    width: 24px;
  }
}

.section-talents {
  margin-top: 55px;
  margin-bottom: 30px;
}
.section-talents__container {
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-auto-flow: column;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding-bottom: 30px;
}
.team-member {
  width: 200px;
  height: 382px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  cursor: pointer;
  color: #000;
}
.team-member img {
  width: 100%;
  margin-bottom: 8px;
}
.team-member__name {
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  margin: 0px 0px 4px 0px;
}
.team-member__title {
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.1px;
  color: #666;
  margin: 0px;
}
.team-member__years {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.1px;
  color: #51c2e7;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
          align-self: stretch;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin: 12px 0px 0px 0px;
}
@media only screen and (max-width: 780px) {
  .section-talents {
    margin-top: 45px;
  }
}

.section-solutions__container {
  background-color: #010101;
  color: #fff;
  padding-top: 100px;
  padding-bottom: 150px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
@media only screen and (max-width: 1024px) {
  .section-solutions__container {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
  }
}
@media only screen and (max-width: 780px) {
  .section-solutions__container {
    padding-top: 30px;
    padding-bottom: 40px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 98%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 98%, 0 100%);
  }
}
.section-solutions {
  
  opacity: 0;
}
.section-solutions__content {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
}
.solution {
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.solution__title {
  font-size: 22px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  margin-top: 40px;
  margin-bottom: 16px;
  color: #fff;
}
.solution__text {
  max-width: 200px;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.1px;
  color: #cbcbcb;
  margin: 0;
}
.solution__img {
  
  opacity: 0;

  margin-bottom: 0;
}
.solution__btn {
  margin-top: 40px;
}
.solution__btn img {
  margin-left: 5px;
  color: #010101;
}
@media only screen and (max-width: 1024px) {
  .section-solutions__content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    row-gap: 30px;
  }

  .solution {
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }

  .solution:first-child {
    -webkit-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}
@media only screen and (max-width: 780px) {
  .section-solutions__content {
    display: block;
  }

  .solution {
    margin-bottom: 40px;
  }

  .solution__title {
    margin: 5px 0 4px 0;
  }

  .solution__btn {
    margin-top: 20px;
  }

  .solution__img {
    width: 90px;
    margin-bottom: 10px;
  }
  .solution__img img {
    width: 100%;
  }
}

.section-case-studies {
  margin-top: 25px;
  margin-bottom: 50px;
}
.section-case-studies__container {
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 193px);
  grid-auto-flow: column;
  overflow-x: scroll;
  scroll-behavior: smooth;

  
  padding-bottom: 40px;
}
.case-study {
  
  opacity: 0;

  width: 193px;
  position: relative;
  overflow: hidden;
  height: 353px;
  padding: 30px 18px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  transition: box-shadow 0.1s ease;
  cursor: pointer;
}
.case-study:hover {
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}
.case-study .image-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40.59%,
    rgba(0, 0, 0, 0.56115) 64.4%,
    #000000 100%
  );
}
.case-study img:first-child {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  left: 0;
  top: 0;
}
.case-study__logo {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  width: 60px;
}
.case-study p {
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  margin: 12px 0px 0px 0px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
@media only screen and (max-width: 780px) {
  .section-case-studies {
    margin-bottom: 20px;
  }
}
.resource-card {
  position: relative;
  transition: box-shadow 0.1s ease;
  cursor: pointer;
  min-height: 206px;
  background-color: white;
}
.resource-card:hover {
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}
.resource-card > img {
  position: absolute;
  z-index: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.resource-card .image-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0.85) 27.35%,
    rgba(0, 0, 0, 0.75) 47.99%,
    rgba(0, 0, 0, 0.6) 100%
  );
}
.resource-card__body {
  padding: 20px;
  color: #fff;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.resource-card__subtitle {
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 5px;
  z-index: 2;
}
.resource-card__title {
  font-size: 16px;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  line-height: 110%;
  z-index: 2;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.resource-card__footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  z-index: 2;
}
.resource-card__footer img {
  display: inline-block;
  color: #ffc72d;
  cursor: pointer;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  margin-left: 5px;
}
.section-clients__container {
  position: relative;
  overflow: hidden;
}
.section-clients__bg {
  height: 85%;
  width: 140%;
  background-color: #010101;
  position: absolute;
  left: -20%;
  bottom: 0;
  border-radius: 100% 100% 0 0;
}
.section-clients {
  position: relative;
  overflow: hidden;
}
.section-clients__content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;

  position: relative;
  padding: 48px 0 20px 0;
  max-width: 810px;
  background: #fff;
  margin: 40px auto;
  box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.15);
}
.section-clients__content-logos {
  align-self: center;

  opacity: 0.5;
  padding: 0 20px 30px 20px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px 30px;
}
.section-clients__logo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;

  

  max-height: 115px;
  width: 156px;
  margin: 0;
}
.section-clients__logo img {
  width: 100%;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
@media only screen and (max-width: 1024px) {
  .section-clients__content {
    max-width: 554px;
  }

  .section-clients__content-logos {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media only screen and (max-width: 780px) {
  .section-clients__logo {
    width: 125px;
  }
  .section-clients__bg {
    bottom: -50px;
    border-radius: 30% 30% 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .section-clients__content {
    max-width: 85%;
  }

  .section-clients__content-logos {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.onload-animate-in {
  opacity: 0%;
}
.hero {
  margin-top: min(9vh, 110px);
  margin-bottom: min(9vh, 110px);
}
.hero h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 140%;
  margin-bottom: 16px;
}
.hero h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 160%;
  margin-bottom: 32px;
}
@media only screen and (max-width: 780px) {
  .hero {
    width: 100%;
    margin-top: min(10vh, 110px);
    margin-bottom: 50vh;

    padding-left: 28px;
    padding-right: 28px;
  }

  .hero-animation {
    position: absolute;
    top: min(55vh, 540px);
    width: 80%;
  }

  .hero h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 136%;
    margin-top: 0;
  }
  .hero h2 {
    font-size: 18px;
    line-height: 160%;
  }
}
@media only screen and (min-width: 780px) {
  .hero {
    margin-top: min(7vh, 110px);
    margin-bottom: min(7vh, 110px);
  }

  .hero-animation {
    top: 120px;
  }

  .hero h2 {
    font-size: 16px;
    line-height: 140%;
  }
}
@media only screen and (min-width: 1024px) {
  .hero {
    margin-top: min(9vh, 110px);
    margin-bottom: min(9vh, 110px);
  }

  .hero h2 {
    font-size: 20px;
    line-height: 160%;
  }
}
@media only screen and (min-width: 1440px) {
  .hero {
    margin-top: min(15vh, 150px);
    margin-bottom: min(15vh, 150px);
  }

  .hero h1 {
    font-size: 56px;
    line-height: 114%;
  }

  .hero-animation {
    top: 240px;
  }
}

.section-services__container {
  background-color: #000;
  color: #fff;
  padding: 90px 0;
  margin: 20px 0;
  -webkit-clip-path: polygon(0 0, 100% 12%, 100% 90%, 0 100%);
          clip-path: polygon(0 0, 100% 12%, 100% 90%, 0 100%);
}
@media only screen and (max-width: 1440px) {
  .section-services__container {
    -webkit-clip-path: polygon(0 0, 100% 10%, 100% 94%, 0 100%);
            clip-path: polygon(0 0, 100% 10%, 100% 94%, 0 100%);
  }
}
@media only screen and (max-width: 1024px) {
  .section-services__container {
    -webkit-clip-path: polygon(0 0, 100% 5%, 100% 96%, 0 100%);
            clip-path: polygon(0 0, 100% 5%, 100% 96%, 0 100%);
  }
}
@media only screen and (max-width: 780px) {
  .section-services__container {
    -webkit-clip-path: polygon(0 0, 100% 2%, 100% 98%, 0 100%);
            clip-path: polygon(0 0, 100% 2%, 100% 98%, 0 100%);
    padding: 90px 0 70px 0;
  }
}
.services {
  display: grid;
  grid-gap: 50px 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  -webkit-align-items: start;
      -ms-flex-align: start;
          align-items: start;
}
.service {
  display: grid;
  grid-template-columns: auto 1fr;
}
.service__icon {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.service__icon img {
  margin-top: -15px;
  margin-left: -20px;
  width: 120px;
}
.service-info h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  margin: 0;
  margin-bottom: 8px;
  line-height: 110%;
}
.service-info p {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.1px;
  max-width: 190px;
  color: #cbcbcb;
}
.service-info span {
  
  opacity: 0;

  display: inline-block;
  border: none;
  outline: none;
  background-color: transparent;
  color: #ffc72d;
  margin-top: 20px;
  cursor: pointer;
  transition: padding 0.1s ease;
}
.service:hover span {
  padding-left: 5px;
}
@media only screen and (max-width: 780px) {
  .services {
    grid-gap: 20px 30px;
  }
  .service-info a {
    margin-top: 10px;
  }
}

.section-resources {
  margin-top: 30px;
  margin-bottom: 60px;
}
.resource-card__container {
  display: grid;
  grid-template-rows: repeat(auto-fill, 206px);
  grid-template-columns: repeat(auto-fit, minmax(275px, 275px));
  grid-auto-flow: column dense;
  grid-auto-columns: minmax(275px, 1fr);
  gap: 32px 30px;

  overflow-x: scroll;
  scroll-behavior: smooth;
  padding-bottom: 30px;
}
.resource-card:first-child {
  grid-row: 1 / span2;
  grid-column: 1;
}
.resource-card:first-child .resource-card__title,
.resource-card:first-child .resource-card__subtitle {
  color: black;
}
.resource-card:first-child .image-filter {
  background: linear-gradient(
    0.06deg,
    rgba(0, 0, 0, 0.9) 0.06%,
    rgba(0, 0, 0, 0) 53.07%,
    rgba(255, 255, 255, 0) 58.48%,
    rgba(255, 255, 255, 0.793554) 77.88%,
    rgba(255, 255, 255, 0.95) 99.94%
  );
}
@media only screen and (max-width: 780px) {
  .section-resources {
    margin-bottom: 40px;
  }
  .resource-card__subtitle {
    letter-spacing: 0.5px;
  }
}

.bg-graphic--top {
  position: absolute;
  z-index: -2;
  top: 0;
  left: calc((100% - min(80%, 1024px)) / 2);
  width: min(80%, 1024px);
  height: 100px;
  overflow: visible;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bg-graphic--top-left {
  width: 30px;
  left: 0px;
}
.bg-graphic--top-right {
  width: 140px;
  right: 0px;
}
.bg-graphic--top-right,
.bg-graphic--top-left {
  position: absolute;
  top: 0px;
  z-index: -1;
  object-fit: contain;
}
@media only screen and (max-width: 780px) {
  .bg-graphic--top {
    top: 0;
    width: 100%;
    left: 0;
    height: 100px;
    overflow-x: hidden;
  }

  .bg-graphic--top-left {
    width: 25px;
    top: auto;
    bottom: 0;
    left: 10vw;
  }
  .bg-graphic--top-right {
    width: 100px;
    top: auto;
    bottom: 0;
    right: 10vw;
  }
}
.bg-graphic--team {
  top: 0;
  height: 100vh;
  width: 100%;
  overflow: visible;
}
.bg-graphic--team img {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
}
.bg-graphic--resources {
  top: -400px;
  height: 100vh;
  width: 60%;
  right: 0;
  overflow: visible;
  left: auto;
}
@media only screen and (max-width: 780px) {
  .bg-graphic--resources {
    top: -40vh;
    height: 100vh;
    width: 660px;
    right: 0;
    overflow: visible;
    left: auto;
  }
}
@media only screen and (min-width: 1440px) {
  .bg-graphic--resources {
    top: -300px;
    height: 830px;
    width: 50%;
    right: 0;
    overflow: visible;
    left: auto;
  }
}
.bg-graphic--resources img {
  z-index: -1;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.talent-team {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 60px 0 32px 0;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.talent-team__logo {
  display: block;
  width: 200px;
}
.talent-team__content p {
  font-size: 16px;
  margin-bottom: 16px;
}
.talent-team__heading img {
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  transition: padding 0.1s ease;
}
.talent-team__heading {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 14px 0;
  color: #000;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.talent-team__heading:hover img {
  padding-left: 5px;
}
.section-embedded__container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 24px;
  padding: 48px 0;
  position: relative;
}
.iframe-container {
  width: 100%;
  max-width: 624px; 
  aspect-ratio: 16 / 9;
  position: relative;
}
.iframe-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.what-we-offer-title {
  margin-bottom: 48px;
}
@media only screen and (max-width: 780px) {
  .talent-team {
    display: block;
  }
}
